<div class="content">
  <div class="card">
    <h2 class="card-title">Product Listing</h2>
    <br />
    <fm-table
      [columns]="cols"
      [value]="
        currentProductListing[0] && currentProductListing[0].product_listings
      "
    >
      <ng-template fmTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template fmTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            {{ rowData[col.field] }}
          </td>
        </tr>
      </ng-template>
    </fm-table>
  </div>

  <div class="card">
    <div class="fm-cointainer">
      <fm-dataList
        [value]="baseIndexRatesLeft"
        header="Base index Rates"
        [isCompressed]="true"
      ></fm-dataList>
      <fm-dataList
        [value]="baseIndexRatesRight"
        header=""
        [isCompressed]="true"
      ></fm-dataList>
    </div>
  </div>

  <div class="card">
    <h2 class="card-title">Assumptions</h2>
    <br />
    <div [innerHTML]="currentAssumptionsForFixedRate"></div>
  </div>
</div>
