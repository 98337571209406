import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent {
  @Input() baseIndexRates: object[];
  @Input() baseIndexRatesLeft: object[];
  @Input() baseIndexRatesRight: object[];
  @Input() currentProductListing: any;
  @Input() currentAssumptionsForFixedRate: any;
  cols: any[];
  constructor() {}
  ngOnInit() {
    this.cols = [
      { field: 'term', header: 'Term(yrs)' },
      { field: 'yld_maint', header: 'Yield Maintenance (Years)' },
      { field: 'idx_nme', header: 'Index' },
      {
        field: 'reqd_netsprd',
        header: 'Fannie Mae Pass Through Spread Range',
      },
      {
        field: 'reqd_netyld',
        header: 'Fannie Mae Pass Through Rate Range',
      },
    ];
  }
}
